.noti-detail {
  .MuiCardContent-root {
    padding: 16px 8px;
  }

  .noti-card-wrap {
    .MuiGrid-grid-xs-2 {
      flex: 0 0 35px;
      max-width: 35px;
      flex-basis: 35px;
    }

    .MuiGrid-grid-xs-true {
      max-width: calc(100% - 35px);

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }

    .MuiSvgIcon-root {
      width: 35px;
      height: 35px;
    }
  }
}