.customer-journey-wrap {
  padding-bottom: 2rem;
  .MuiExpansionPanelDetails-root {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  > .MuiPaper-root {
    background: none;
    box-shadow: none;
    margin: 0 !important;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;

    &:before {
      display: none;
    }

    &:after {
      display: block;
      content: "";
      height: 26px;
      width: 18px;
      background: url("https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-line3@3x.png")
        no-repeat;
      background-size: auto 26px;
      background-position: center bottom;
      transition: ease-in-out all 0.2s;
    }

    &.Mui-expanded {
      &:after {
        height: 0;
      }
    }

    &:nth-last-child(1) {
      &:after {
        display: none;
      }
    }

    .MuiExpansionPanelSummary-root {
      min-height: 0;
      width: auto;
      flex: 0 0 auto;
      margin-bottom: 11px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        border-radius: 20px;
        top: 10px;
        bottom: 10px;
        left: 12px;
        right: 12px;
        z-index: 1;
        background: #c3c4d7;
      }

      &.Mui-expanded:before {
        background: #373d49;
      }

      .MuiExpansionPanelSummary-content {
        margin: 0;
        position: relative;
        z-index: 2;

        .MuiTypography-root {
          color: #fff;

          &.MuiTypography-body2 {
            position: absolute;
            white-space: nowrap;
            top: calc(100% + 4px);
            left: calc(50% + 6px);
            transform: translateX(-50%);
            color: #949494;
          }
        }

        + .MuiButtonBase-root {
          position: relative;
          z-index: 2;
          padding-left: 0px;
          padding-right: 4px;

          .MuiSvgIcon-root {
            fill: #fff;
          }
        }
      }
    }

    .MuiCollapse-container {
      width: 100%;

      .date-wrap {
        width: 100%;
        position: relative;
        > div {
          width: calc(50% - 50px);
          background: #fff;
          border-radius: 4px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
          padding: 8px;
          position: relative;
          min-height: 71px;
          .time {
            color: #949494;
            margin: 2px 0;
            .MuiSvgIcon-root {
              fill: #c6c6c6;
              margin-bottom: 2px;
            }
          }
          .MuiTypography-caption {
            color: #949494;
          }
          .status-icon {
            width: 40px;
            height: 40px;
            background-size: contain;
          }
          &:after {
            width: 0;
            height: 0;
            margin: auto;
            content: "";
            display: block;
            border-style: solid;
            border-color: transparent transparent transparent #fff;
            border-width: 0.5em 0 0.5em 0.5em;
            position: absolute;
            left: 100%;
            top: 20px;
          }
          &:before {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            left: -51px;
            top: 35px;
            background: #556080;
          }

          &:nth-child(2) {
            .link-line {
              right: auto;
              left: calc(100% + 13px);
              width: 73px;
              &:after {
                top: 8px;
                left: 2px;
                width: 72px;
                height: 73px;
              }
              &:before {
                background: #f2f3f8;
                display: block;
                content: "";
                width: 100%;
                position: absolute;
                top: 11px;
                height: 67px;
              }
            }
          }

          &:nth-child(n + 3) {
            margin-top: 12px;
          }
        }
        &:nth-last-child(1) {
          > div:nth-last-child(1):before {
            display: none !important;
          }
        }

        &:nth-child(even) {
          > div {
            &:nth-child(2) {
              .link-line {
                right: calc(100% + 11px);
              }
            }

            &:before {
              left: auto;
              right: -51px;
            }
          }
        }

        &:nth-child(odd) {
          > div {
            margin-left: calc(50% + 50px);
            &:after {
              left: auto;
              right: 100%;
              border-width: 0.5em 0.5em 0.5em 0;
              border-color: transparent #fff transparent transparent;
            }
            &:nth-child(n + 3) {
              .link-line {
                left: auto;
                right: calc(100% + 37px);
              }
            }
            .link-line {
              left: auto;
              right: calc(100% + 13px);
            }
          }
        }
        .link-line {
          position: absolute;
          padding: 0;
          width: 26px;
          height: 100%;
          border-radius: 0;
          box-shadow: none;
          top: -14px;
          left: calc(100% + 37px);
          background: none;
          &:after {
            content: "";
            position: absolute;
            width: 34px;
            height: 32px;
            border-radius: 50%;
            left: -4px;
            right: auto;
            top: 25px;
            background: #fff;
            z-index: 2;
          }
        }

        > p:nth-child(1) {
          display: block;
          position: relative;
          margin-bottom: -70px;
          width: 73px;
          height: 66px;
          left: calc(50% - 36px);
          top: -6px;
          // background: #f2f3f8;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          font-size: 1.5rem;
          line-height: 1;
          padding-top: 18px;
          z-index: 3;

          span {
            display: block;
            font-size: 0.8rem;
          }

          &:before {
            width: 71px;
            height: 70px;
            display: block;
            position: absolute;
            content: "";
            top: -2px;
            left: 3px;
            z-index: -1;
          }

          &:after {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: block;
            position: absolute;
            content: "";
            top: 12px;
            left: 16px;
            z-index: -1;
            background: #556080;
          }
        }
        .subtitle {
          white-space: nowrap;
          &.font-yellow {
            color: #ffb821;
          }
          &.font-blue {
            color: #36a3f7;
          }
          &.font-red {
            color: #e61d16;
          }
          &.font-pink {
            color: #fd2892;
          }
          &.active:after {
            content: "Active";
            display: inline-block;
            margin-left: 6px;
            padding: 2px 18px;
            background: #eaeaea;
            border-radius: 20px;
            color: #949494;
          }

          &.inactive:after {
            content: "Inactive";
            display: inline-block;
            margin-left: 6px;
            padding: 2px 18px;
            background: #eaeaea;
            border-radius: 20px;
            color: #949494;
          }
        }

        > .status-success {
          .link-line:after {
            background: #f2f3f8
              url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-success-small.svg)
              no-repeat;
            background-size: 100% auto;
            background-position: center center;
          }
          &:nth-child(2) {
            .link-line:after {
              background: url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-success-right.svg)
                no-repeat;
              background-size: 100% auto;
              background-position: center center;
            }
          }
        }

        &:nth-child(even) {
          > .status-success:nth-child(2) {
            .link-line:after {
              background: url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-success-left@1x.svg)
                no-repeat;
              background-size: 100% auto;
              background-position: center center;
              left: -2px;
            }
          }
        }

        > .status-inprogress {
          .link-line:after {
            background: #f2f3f8
              url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-inprogress-small.svg)
              no-repeat;
            background-size: 100% auto;
            background-position: center center;
          }
          &:nth-child(2) {
            .link-line:after {
              background: url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-inprogress-right@1x.svg)
                no-repeat;
              background-size: 100% auto;
              background-position: center center;
            }
          }
        }

        &:nth-child(even) {
          > .status-inprogress:nth-child(2) {
            .link-line:after {
              background: url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-inprogress-left@1x.svg)
                no-repeat;
              background-size: 100% auto;
              background-position: center center;
              left: -2px;
            }
          }
        }

        > .status-cancel {
          .link-line:after {
            background: #f2f3f8
              url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-cancel-small.svg)
              no-repeat;
            background-size: 100% auto;
            background-position: center center;
          }
          &:nth-child(2) {
            .link-line:after {
              background: url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-cancel-right@1x.svg)
                no-repeat;
              background-size: 100% auto;
              background-position: center center;
            }
          }
        }

        &:nth-child(even) {
          > .status-cancel:nth-child(2) {
            .link-line:after {
              background: url(https://true-crm-90b0e.firebaseapp.com/assets/images/journey/status-cancel-left@1x.svg)
                no-repeat;
              background-size: 100% auto;
              background-position: center center;
              left: -2px;
            }
          }
        }
      }
    }
  }
}
