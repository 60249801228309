.campaign-tabs,
.notification-tabs {
  > .MuiBox-root {
    padding: 0;
    > div:nth-last-child(1) {
      .MuiDivider-root:nth-last-child(1) {
        display: none;
      }
    }
  }
}