.pending-task {
  overflow: visible !important;
  position: relative;
  background-image: linear-gradient(to right, rgb(111, 105, 198), rgb(193, 85, 121));
  .MuiTooltip-popper {
    pointer-events: all;
    transform: translate3d(-100%, 0, 0px) !important;
    max-width: none;
    width: 800px;
  }
  .MuiTooltip-tooltip {
    background: #fff !important;
    display: block;
    max-width: none !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiTooltip-arrow {
    color: #fff !important;
  }
}
