.navbar-crm {
  position: fixed;
  width: 47px;
  height: 100vh;
  color: #ffffff;
  padding: 0;
  z-index: 9999;
  a:hover {
    color: #f4516c;
  }
}

.navbar-slide {
  position: fixed;
  height: 100vh;
  width: 0;
  color: #ffffff;
  left: 47px;
  z-index: 9998;
}

.navbar-slide-active {
  width: 230px;
}

.navbar-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  z-index: 9997;
}
