.single-content-wrap {
  overflow: visible !important;
  .single-content-menu {
    margin-top: -31px;
    .nav-slide {
      background: #f2f3f8;
      position: absolute;
      width: auto;
      margin-left: 38px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-out;
      .MuiTab-root {
        opacity: 1;
        color: rgba(0, 0, 0, 0.85);
        position: relative;
        z-index: 1;

        &.Mui-selected {
          color: #fff;
        }
      }
      &.active {
        opacity: 1;
        pointer-events: all;
        z-index: 1101;
        + .nav-slide-dim {
          pointer-events: all;
        }
      }
      .MuiTabs-indicator {
        width: 100%;
      }
      + .nav-slide-dim {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
      }
    }
  }
  .MuiBox-root {
    padding: 0;
  }

  .circle-border {
    border-radius: 50%;
    border: 1px solid #f4f4f4;
    padding: 5px;
  }
}
