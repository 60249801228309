.asset-contact {
  .items {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }

  .MuiTypography-root {
    &.text-nowrap {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .textnormal {
    white-space: normal;
  }

  .swiper-container {
    padding-bottom: 20px;

    &.swiper-container-horizontal {
      & > .swiper-pagination-bullets {
        bottom: -3px;

        .swiper-pagination-bullet {
          margin: 0 2px;

          &.swiper-pagination-bullet-active {
            background: #2c2d3d;
          }
        }
      }

      .swiper-button-next {
        background-image: none;
        width: 32px;
        height: 100%;
        top: 12px;
        right: -8px;
      }

      .swiper-button-prev {
        background-image: none;
        width: 32px;
        height: 100%;
        top: 12px;
        left: -8px;
      }
    }

    .swiper-wrapper {
      width: 84%;
    }
  }
}
