.tabs-toggle.MuiAppBar-root {
  background: #f2f3f8;
  width: auto;
  display: inline-block;
  box-shadow: none;
  .MuiTabs-root {
    min-height: 20px;
  }
  .MuiTabs-flexContainer {
    z-index: 1;
    position: relative;
    .MuiTab-root {
      color: rgba(0, 0, 0, 0.85);
      min-width: 80px;
      min-height: 20px;
      &.Mui-selected {
        color: #fff;
      }
    }
  }
  .MuiTabs-indicator {
    height: 100%;
  }
}

.recharts-wrapper {
  max-width: 100%;
  margin: 0 auto;
  .recharts-surface {
    max-width: 100%;
  }
}
