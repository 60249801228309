.billing-wrap {
  .MuiExpansionPanel-root {
    margin: 0;
    border-radius: 4px;
    box-shadow: none;
    position: relative;
    > div {
      position: relative;
      z-index: 1;
      align-items: flex-start;
      .MuiExpansionPanelSummary-expandIcon {
        margin: 4px -24px;
      }
    }
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      width: 5px;
      bottom: 0;
      position: absolute;
      background: #8e8e8e;
      transition: 0.3s all ease-out;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.Mui-expanded {
      margin: 0 0 16px 0;
      color: #fff;
      &:after {
        width: 100%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:last-child {
        margin: 0;
      }
      .MuiTypography-colorTextSecondary {
        color: #fff;
      }
      .MuiExpansionPanelSummary-expandIcon {
        .MuiSvgIcon-root {
          fill: #fff;
        }
      }
    }
    &:before {
      display: none;
    }
  }
  .box-header {
    font-size: 1.2rem;
    font-weight: bold;
    width: 100%;

    > span {
      font-size: 0.8rem;
    }
  }
  .MuiExpansionPanelSummary-content {
    flex-direction: column;
    &.Mui-expanded {
      margin: 12px 0;
    }
  }
  .day-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 3rem;
    position: relative;

    button {
      background: #fff;
      &:hover {
        background: #f2f3f8;
      }
    }
    > div {
      flex-direction: column;
      &.payment-wrap {
        .MuiExpansionPanel-root {
          &:after {
            background: #73c0a1;
          }
          &.Mui-expanded {
            &.toInvoice,
            &.toPayment {
              &:before {
                display: block;
                content: "";
                position: absolute;
                height: 2px;
                width: 1.5rem;
                background: #73c0a1;
                top: 74px;
                left: 100%;
                opacity: 1;
              }
            }

            &.toAdjustment {
              &:before {
                width: calc(100% + 4.5rem);
                display: block;
                content: "";
                position: absolute;
                height: 2px;
                background: #73c0a1;
                top: 74px;
                left: 100%;
                opacity: 1;
              }
            }
          }
          &.fromPayment {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #73c0a1;
              bottom: 30px;
              left: 100%;
              top: auto;
              right: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-right: 7px solid #73c0a1;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 24px;
                left: auto;
                top: auto;
                right: -6px;
                opacity: 1;
              }
            }
          }
          &.fromInvoice {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #d3536c;
              bottom: 30px;
              left: 100%;
              top: auto;
              right: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-right: 7px solid #d3536c;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 24px;
                left: auto;
                top: auto;
                right: -6px;
                opacity: 1;
              }
            }
          }
          &.fromAdjustment {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #6e69c1;
              bottom: 30px;
              left: 100%;
              top: auto;
              right: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-right: 7px solid #6e69c1;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 24px;
                left: auto;
                top: auto;
                right: -6px;
                opacity: 1;
              }
            }
          }
        }
      }
      &.invoice-wrap {
        .MuiExpansionPanel-root {
          &:after {
            background: #d3536c;
          }
          &.Mui-expanded {
            &.toPayment {
              &:before {
                display: block;
                content: "";
                position: absolute;
                height: 2px;
                width: 1.5rem;
                background: #d3536c;
                top: 74px;
                right: 100%;
                left: auto;
                opacity: 1;
              }
            }
            &.toAdjustment {
              > div:nth-child(1) {
                &:after {
                  display: block;
                  content: "";
                  position: absolute;
                  height: 2px;
                  width: 1.5rem;
                  background: #d3536c;
                  top: 74px;
                  left: 100%;
                  opacity: 1;
                }
              }
            }
          }
          &.fromPayment {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #73c0a1;
              bottom: 10px;
              right: 100%;
              top: auto;
              left: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-left: 7px solid #73c0a1;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 4px;
                right: auto;
                top: auto;
                left: -6px;
                opacity: 1;
              }
            }
          }
          &.fromAdjustment {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #6e69c1;
              bottom: 30px;
              left: 100%;
              top: auto;
              right: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-right: 7px solid #6e69c1;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 24px;
                left: auto;
                top: auto;
                right: -6px;
                opacity: 1;
              }
            }
          }
        }
      }
      &.adjustment-wrap {
        .MuiExpansionPanel-root {
          &:after {
            background: #6e69c1;
          }
          &.Mui-expanded {
            &.toInvoice,
            &.toAdjustment {
              &:before {
                display: block;
                content: "";
                position: absolute;
                height: 2px;
                width: 1.5rem;
                background: #6e69c1;
                top: 74px;
                right: 100%;
                left: auto;
                opacity: 1;
              }
            }

            &.toPayment {
              &:before {
                width: calc(100% + 4.5rem);
                display: block;
                content: "";
                position: absolute;
                height: 2px;
                background: #6e69c1;
                top: 74px;
                right: 100%;
                left: auto;
                opacity: 1;
              }
            }
          }
          &.fromPayment {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #73c0a1;
              bottom: 10px;
              right: 100%;
              top: auto;
              left: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-left: 7px solid #73c0a1;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 4px;
                right: auto;
                top: auto;
                left: -6px;
                opacity: 1;
              }
            }
          }
          &.fromInvoice {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #d3536c;
              bottom: 10px;
              right: 100%;
              top: auto;
              left: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-left: 7px solid #d3536c;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 4px;
                right: auto;
                top: auto;
                left: -6px;
                opacity: 1;
              }
            }
          }
          &.fromAdjustment {
            &:before {
              display: block;
              content: "";
              position: absolute;
              height: 2px;
              width: 1.5rem;
              background: #6e69c1;
              bottom: 10px;
              left: auto;
              right: 100%;
              top: auto;
              opacity: 1;
            }
            > div:nth-child(1) {
              &:after {
                display: block;
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                border-left: 7px solid #6e69c1;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                bottom: 4px;
                right: auto;
                top: auto;
                left: -6px;
                opacity: 1;
              }
            }
          }
        }
      }
      > div {
        width: 100%;
        margin-bottom: 16px;
      }
    }
    .theDate {
      position: absolute;
      left: 3rem;
      right: 0rem;
      top: 0;
      z-index: 1;
      > div {
        position: absolute;
        background: rgba(242, 243, 248, 0.8);
        top: 0;
        width: auto;
        left: auto;
        margin-right: -1.5rem;
        right: 33.33%;

        &.have-payment {
          right: 100%;
        }
        &.have-invoice {
          right: 66.66%;
        }

        p {
          font-size: 1rem;
          text-align: center;
          padding-right: 0.5rem;
          span {
            font-size: 1.4rem;
            display: block;
            color: #000;
            width: 32px;
            height: 32px;
            line-height: 32px;
            border-radius: 50%;
          }
        }
      }
      &.current {
        span {
          background: #373d49;
          color: #fff;
        }
      }
    }
  }
  .MuiExpansionPanelDetails-root {
    padding-bottom: 16px;
  }
  .diagram-wrap {
    position: relative;
    .year-wrap {
      padding-bottom: 10px;
      .theYear {
        text-align: center;
        position: relative;
        > .MuiTypography-body1 {
          color: #fff;
          background: #373d49;
          border-radius: 30px;
          padding: 2px 16px;
          margin: 5px auto;
          display: inline-block;
          position: relative;
          z-index: 1;
        }
        &:before {
          position: absolute;
          top: 50%;
          left: 1.5rem;
          right: 1.5rem;
          border-bottom: 1px dashed #949494;
          content: "";
          display: block;
        }
      }
      > .MuiTypography-body2 {
        color: #949494;
        text-align: center;
        width: 100%;
        padding-bottom: 1rem;
      }
    }
  }
}

.full-box-wrap {
  .day-wrap {
    padding-left: 4rem;
    margin: 0 auto;
  }
  .theDate {
    > div {
      right: 100% !important;
      padding-right: 0.5rem;
      background: none !important;
    }
  }
  .detail-wrap {
    background: #fff;
    border-radius: 4px;
    margin-bottom: 0 !important;
    padding-bottom: 16px;
  }
  .full-box {
    padding-top: 8px;
    > div {
      > div:nth-child(1) {
        max-width: 100px;
      }
    }

    button {
      background: #d3536c;
      color: #fff;
      &:hover {
        background: #be3b55;
      }
    }
  }
  .type-payment {
    width: 5px !important;
    height: 100%;
    background: #73c0a1;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .type-invoice {
    width: 5px !important;
    height: 100%;
    background: #d3536c;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .type-adjustment {
    width: 5px !important;
    height: 100%;
    background: #6e69c1;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.invoice-document {
  width: 100vw;
  max-width: calc(77.57vh - 64px);
  height: 100vh;
  background-size: contain;
  background-position: center center;
}
