//   .tab {
//     background-color: #ececf2;
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
//   }
.headtabs-customer {
  background-color: #f2f3f7;
  border-radius: 20px;
}

// --------------------------------------------------------------------
.MuiAppBar-root {
  &.headtabs {
    background: none;
    box-shadow: none;
    position: relative;
    > .MuiTabs-root {
      min-height: 36px;
    }
    button.MuiTab-root {
      background-color: #ececf2;
      color: rgb(55, 61, 73);
      margin-right: 2px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      min-width: 0;
      min-height: 36px;
      cursor: default;

      .MuiBadge-badge {
        transform: translate(calc(100% + 2px), -1px);
      }

      &.Mui-selected {
        background: #fff;

        .MuiBadge-badge {
          color: #d5526b;
          background: #fff;
        }
      }
    }

    span.MuiTabs-indicator {
      background-color: transparent;
    }

    &:after {
      display: none;
    }

    .MuiTabs-indicator {
      background: #d5526b;
    }
  }
}
