.app-info {
  padding: 0;

  .app-icon {
    position: relative;

    .app-os {
      position: absolute;
      right: calc(50% - 40px);
      bottom: 12px;
    }
  }

  .app-desc {
    span {
      line-height: 1;
    }
  }

  .swiper-container {
    padding-bottom: 20px;

    &.swiper-container-horizontal {
      & > .swiper-pagination-bullets {
        bottom: -3px;

        .swiper-pagination-bullet {
          margin: 0 2px;

          &.swiper-pagination-bullet-active {
            background: #2c2d3d;
          }
        }
      }

      .swiper-button-next {
        background-image: none;
        width: 32px;
        height: 100%;
        top: 12px;
        right: -8px;
      }

      .swiper-button-prev {
        background-image: none;
        width: 32px;
        height: 100%;
        top: 12px;
        left: -8px;
      }
    }

    .swiper-wrapper {
      width: 92%;
    }
  }
}
