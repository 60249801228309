.offer {
    .background-light-gray {
        background-color: #f2f3f8;
        border-radius: 6px;
    }
    .avatar-user {
        .MuiAvatar-root {
            &.MuiAvatar-circle {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }
        }
    }
    .add-offer {
        .MuiSvgIcon-root {
            margin-right: 8px;
        }
        button {
            .MuiTypography-h6 {
                text-transform: none;
            }
        }
    }
}