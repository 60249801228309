.MuiPopover-root {
  &#attach-menu {
    .MuiListItem-button {
      &:hover {
        background-color: rgba(244, 81, 108, .2);
      }
    }

    .MuiSvgIcon-root {
      color: #f4516c;
    }
  }
}