.contact-wrap {
  .MuiInput-underline {
    &:before,
    &:after {
      display: none;
    }
  }
  .MuiInputBase-input {
    padding: 1px 3px;
    margin: 1px 0;
    border: 1px solid #e0e0e0;
    background: #f2f3f8;
    border-radius: 3px;
    &:read-only {
      border: 1px solid transparent;
      background: none;
    }
  }
}
