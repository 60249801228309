.headbar-wrap {
  max-width: calc(100% - 30px);
  .headbar-icon {
    .MuiSvgIcon-root {
      fill: #f4516c;
    }
    + h6 {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: linear-gradient(to right, #6f69c5, #bf5579);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  + .MuiIconButton-root {
    margin: -10px;
  }
}
