.campaign-notification {
  position: relative;
  overflow: visible !important;
  .MuiTooltip-popper {
    pointer-events: all;
    transform: translate3d(-100%, 0, 0px) !important;
  }
  .MuiTooltip-tooltip {
    background: #fff !important;
    display: block;
    width: 450px;
    max-width: 450px !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiGrid-container {
    position: relative;
  }
  .MuiTooltip-arrow {
    color: #fff !important;
  }
  > .campaign-card {
    .MuiCardContent-root {
      padding: 16px;
    }
    .campaign-name {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
