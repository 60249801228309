.profile {
  .MuiButton-contained {
    &.no-boxshadow {
      box-shadow: none;
    }
    &:hover {
      &.no-boxshadow {
        box-shadow: none;
      }
    }
    &.text-normal {
      text-transform: unset;
    }
  }
}
