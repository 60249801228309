.ivr-call-flow {
    .MuiChip-root {
        &.first-flow {
            background-color: #f4516c;
            color: #fff;
        }
    }
    .flow-line {
        height: 1px;
        width: 99%;
        background-color: #f4516c;
        display: inline-block;
        margin: 16px 0 0;
        position: relative;
        .flow-arrow {
            width: 0;
            height: 0;
            border-top: 4.5px solid transparent;
            border-left: 6px solid #f4516c;
            border-bottom: 4.5px solid transparent;
            position: absolute;
            right: -1px;
            bottom: -4px;
        }
    }
    .flow-line-downleftdown {
        position: relative;
        .downleftdown-item-ver {
            position: absolute;
            height: 12px;
            width: 1px;
            background-color: #f4516c;
            bottom: -12px;
            left: 50%;
        }
        .downleftdown-item-hor {
            position: absolute;
            height: 1px;
            width: 150%;
            background-color: #f4516c;
            bottom: -12px;
            right: 50%;
            &.md {
                width: 300%;
            }
            &.lg {
                width: 450%;
            }
            &:before {
                content: '';
                width: 1px;
                height: 12px;
                background-color: #f4516c;
                position: absolute;
                left: 0;
            }
            &:after {
                content: '';
                width: 0;
                height: 0;
                border-left: 4.5px solid transparent;
                border-top: 6px solid #f4516c;
                border-right: 4.5px solid transparent;
                position: absolute;
                left: -4px;
                bottom: -16px;
            }
        }
    }
}