.tabs-toggle-grey.MuiAppBar-root {
  background: #fff;
  width: auto;
  display: inline-block;
  box-shadow: none;
  border: 1px solid #c3c4d4;
  border-radius: 3px;
  .MuiTabs-root {
    min-height: 20px;
  }
  .MuiTabs-flexContainer {
    z-index: 1;
    position: relative;
    .MuiTab-root {
      color: #95959c;
      min-width: 80px;
      min-height: 20px;
      &.Mui-selected {
        color: #fff;
      }
    }
  }
  .MuiTabs-indicator {
    height: 100%;
    background: #c3c4d4;
  }
}

.float-on-pie {
  p {
    font-size: 1.5rem;
    span {
      display: block;
      font-size: 2.3rem;
      line-height: 2rem;
    }
  }
}

.donut-table {
  .MuiTableCell-root {
    padding: 1px 10px;
    border: none;
    vertical-align: top;
    &.MuiTableCell-head {
      line-height: 0.8;
    }
    &.vertical-bottom {
      line-height: 1;
    }
    .rectangle {
      width: 22px;
      height: 14px;
      display: inline-block;
    }
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 1em;
    color: transparent;
  }

  .border-right {
    border-color: rgba(0, 0, 0, 0.65) !important;
  }
}

.circle-red {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  background: #e8442b;
  fill: #fff !important;
  padding: 7px;
}
.circle-blue {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  background: #6dc4da;
  fill: #fff !important;
  padding: 7px;
}
.rating-star {
  .MuiRating-icon {
    .MuiSvgIcon-root {
      fill: #e0e0e0;
    }

    &.MuiRating-iconFilled {
      .MuiSvgIcon-root {
        fill: #fc8309;
      }
    }
  }
}
