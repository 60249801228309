.ant-switch-checked {
  background-color: #f4516c;
}

// .number-persent {
//   position: absolute;
//   margin-left: 6px;
//   top: -4px;
//   z-index: 1;
//   color: #f4516c;
// }
