@import url("https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700,800,900&display=swap");

body,
html {
  font-size: 13px;
  font-family: "Kanit";
  background-color: #f2f3f8;
  overscroll-behavior: none;
}

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
}

a {
  color: unset;
}

a:hover {
  color: unset;
  text-decoration: none;
}

a:focus,
button:focus {
  outline: none !important;
}

.c-default {
  cursor: default;
}

// --------------------------------------------------------------------

.text-main {
  color: #f4516c;
}
.bg-main {
  background-color: #f4516c;
}

.text-yellow {
  color: #e2c262;
}
.bg-yellow {
  background-color: #e2c262;
}

.text-green {
  color: #73bfa2;
}
.bg-green {
  background-color: #73bfa2;
}

.text-gray {
  color: #ececf2;
}
.bg-gray {
  background-color: #ececf2;
}

// --------------------------------------------------------------------
.hov:hover {
  filter: brightness(0.95);
  cursor: pointer;
}
// --------------------------------------------------------------------
.MuiAppBar-root {
  &.tabs-header {
    background: none;
    box-shadow: none;
    position: relative;
    > .MuiTabs-root {
      min-height: 36px;
    }
    button.MuiTab-root {
      background: rgb(223, 225, 234);
      color: rgb(55, 61, 73);
      opacity: 1;
      margin-right: 2px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      min-width: 0;
      min-height: 36px;
      padding: 6px 25px;

      .MuiBadge-badge {
        transform: translate(calc(100% + 2px), -1px);
      }

      &.Mui-selected {
        background: #f4516c;
        color: #fff;

        .MuiBadge-badge {
          color: #f4516c;
          background: #fff;
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #f4516c;
    }

    .MuiTabs-indicator {
      background: #f4516c;
    }
  }
  &.tabs-header-white {
    background: #fff;
    box-shadow: none;
    position: relative;
    padding: 0 10px;
    > .MuiTabs-root {
      min-height: 36px;
    }
    button.MuiTab-root {
      background: none;
      color: rgb(55, 61, 73);
      opacity: 1;
      margin: 0 20px 0 0;
      min-width: 0;
      min-height: 36px;
      padding: 6px 0;

      .MuiBadge-badge {
        transform: none;
        position: relative;
        line-height: 0.9;
        margin: auto 0 auto 5px;
        height: 20px;
        padding: 0 5px;
        border-radius: 30px;
        background: rgba(55, 61, 73, 0.06);
      }

      &.Mui-selected {
        color: #f4516c;

        .MuiBadge-badge {
          background: #f4516c;
          color: #fff;
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.12);
    }

    .MuiTabs-indicator {
      background: #f4516c;
      height: 1px;
    }
  }
}

.max-3-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

// chart
.ant-progress {
  .ant-progress-outer {
    display: flex;
  }
}

.white-nowrap {
  white-space: nowrap;
}

.flex-0-0-a {
  flex: 0 0 auto !important;
  width: auto !important;
}
