.rbc-event {
  background-color: #f4516c;
}

.rbc-event.rbc-selected {
  background-color: #f4516c;
  filter: brightness(0.95);
}

.rbc-event:focus {
  outline: none;
}

.rbc-today {
  background-color: rgba($color: #f4516c, $alpha: 0.1);
}

.rbc-header {
  padding: 15px 5px;
}

.rbc-date-cell {
  padding: 15px;
}

.rbc-current {
  a {
    background-color: #2c2d3d;
    color: #fff !important;
    border-radius: 50%;
    padding: 5px 7px;
  }
}

.rbc-toolbar {
  justify-content: space-between;
  flex-direction: row-reverse;

  .rbc-toolbar-label {
    text-align: left;
  }

  .rbc-btn-group button {
    display: none;
  }

  // .rbc-btn-group button:nth-child(n + 2) {
  //   display: none;
  // }

  // .rbc-btn-group button:first-child:not(:last-child) {
  //   border-radius: 4px;
  //   font-size: 13px;
  // }
}

.schedule > .rbc-month-view {
  display: none;
}

.popup-create {
  .input-textfield {
    width: 100%;

    .MuiInputBase-root {
      border-radius: 4px;

      .MuiInputBase-input {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.MuiFilledInput-underline {
        &:before {
          border-bottom: none;
        }
      }

      &.MuiFilledInput-multiline {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .MuiFormHelperText-root {
      margin: 3px 0 0 0;
    }
  }

  .MuiTableCell-root {
    border: 1px solid rgba(224, 224, 224, 1);
  }
}
