.campaign-detail {
  .MuiDivider-vertical {
    position: absolute;
    top: 0;
    bottom: 0;
    height: auto;
    left: 50%;
  }

  .mt--1 {
    margin-top: -0.3rem;
  }

  .title-popover {
    width: 120px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}