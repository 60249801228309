.asset-profile-list {
  overflow: visible !important;
  .MuiTooltip-popper {
    pointer-events: all;
    max-width: none;
    width: 300px;
  }
  .MuiTooltip-tooltip {
    background: #fff !important;
    display: block;
    max-width: none !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiTooltip-arrow {
    color: #fff !important;
  }
}
